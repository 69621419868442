<template>
   <div class="w-full md:w-2/3 " v-if="isNotAuthenticated">
        <div class="intro-x pt-5 flex flex-col  items-center mb-5">
            <company-logo :theme="dark" />
        </div>

        <div v-if="!next_statge" key="1" class="flex flex-col lg:px-10">
            <h2 class="intro-x font-bold text-2xl xl:text-3xl text-center ">
                Register a new membership
            </h2>
            <div class="intro-x mt-2 text-gray-500 xl:hidden text-center">A few more clicks to sign in to your account.  Welcome to AdCrew! </div>
            <div class="intro-x mt-8 w-full">
                <float-label>
                    <input type="text" v-model="user.first_name" class="intro-x login__input input input--lg border border-gray-300 block w-full" placeholder="First Name *" required>
                </float-label>
                <float-label>
                    <input type="text" v-model="user.last_name" class="intro-x login__input input input--lg border border-gray-300 block mt-4 w-full" placeholder="Last Name *" required>
                </float-label>
                <float-label>
                    <input type="text" id="username" v-model="user.username" class="intro-x login__input input input--lg border border-gray-300 block mt-4 w-full" placeholder="Username" required>
                </float-label>
                <float-label>
                    <input type="email" v-model="user.email" class="intro-x login__input input input--lg border border-gray-300 block mt-4 w-full" placeholder="Email">
                </float-label>
                <float-label>
                    <input type="phone" v-model="user.phone_number" class="intro-x login__input input input--lg border border-gray-300 block mt-4 w-full" required placeholder="Phone *">
                </float-label>
                <float-label>
                    <input type="password" v-model="user.password" class="intro-x login__input input input--lg border border-gray-300 block mt-4 w-full" placeholder="Password *" required>
                </float-label>
                <float-label>
                    <input type="password" v-model="user.password_confirm" class="intro-x login__input input input--lg border border-gray-300 block mt-4 w-full" placeholder="Password Confirmation *" required>
                </float-label>
            </div>
            <!--login/sign up buttons-->
            <div class="pt-8">
              <button class="button button--lg w-32 text-gray-700 border border-gray-300 mr-3" ><router-link :to="{name: 'login'}" class="" >Back To Login</router-link></button>
              <button @click.prevent="next_statge = true" class="button button--lg w-32 text-white bg-theme-1  mt-0">Next <fa-icon :icon="['fas', 'chevron-right']" /></button>
              <!--<button class="button button--lg w-full xl:w-32 text-white bg-theme-1  mt-3 xl:mt-0" @click.prevent="signUp = !signUp">Sign up</button>-->
            </div>
            <!--end of login/sign up buttons -->
        </div>
        <div class="flex flex-col lg:px-10" v-else key="2">
            <h2 class="intro-x font-bold text-2xl xl:text-3xl text-center ">
                Complete registration
            </h2>

            <div class="mt-5 ">
                <float-label>
                    <input type="text" v-model="user.company_name" class="intro-x login__input input input--lg border border-gray-300 block w-full " required placeholder="Company Name *">
                </float-label>
            </div>
            <div class="mt-5 ">
                <float-label>
                    <input type="text" v-model="user.company_url" class="intro-x login__input input input--lg border border-gray-300 block w-full" required placeholder="Company URL * i.e http(s)://yourdomain.com">
                </float-label>
            </div>
            <div class="mt-5 ">
                <float-label>
                    <input type="text" v-model="user.country" class="intro-x login__input input input--lg border border-gray-300 block w-full" required placeholder="Country *">
                </float-label>
            </div>
            <div class="mt-5">
                <float-label>
                    <select v-model="user.type_of_membership" class="login__input w-full input border border-gray-300 block mt-1 w-full" required placeholder="Type of Membership *">
                        <option class="text-xl font-bold" selected disabled>Type of Membership *</option>
                        <option class="text-lg" v-for="name in type_of_membership" :key="name" :value="name">{{ name }}</option>
                    </select>
                </float-label>
            </div>
            <div class="mt-5">
                <float-label>
                    <select v-model="user.interested_in" multiple class="login__input w-full input border border-gray-300 block mt-1 w-full" required placeholder="Intersted In *">
                        <option class="text-xl font-bold" selected disabled>Intersted In *</option>
                        <option class="text-lg" v-for="name in interested_in" :key="name" :value="name">{{ name }}</option>
                    </select>
                </float-label>
            </div>
            <div class="mt-5">
                <float-label>
                    <textarea type="text" v-model="user.websites" style="overflow-y: scroll;" class="login__input w-full input border border-gray-300 block mt-1 w-full" required placeholder="Website(s) * i.e http(s)://yourdomain.com"></textarea>
                </float-label>
            </div>

            <div class="mt-5">
                <div class="intro-x flex items-center text-gray-700 mt-4 text-xs sm:text-sm">
                <input type="checkbox" v-model="terms" class="input border bg-white mr-2" id="agreeTerms" required name="terms" value="agree">
                    <label for="agreeTerms">
                        I agree to the <a href="https://vidintop.com/terms" class="text-theme-1 ml-1">Terms of Service</a>
                    </label>
                </div>
            </div>
            <!-- /.col -->
            <div class="mt-5">
                <button @click.prevent="next_statge = false" class="button button--lg w-32 text-gray-700 border border-gray-300  mr-3"><fa-icon :icon="['fas', 'chevron-left']" /> Back</button>
                <button @click.prevent="signup()" class="button button--lg w-32 text-white bg-theme-1 mt-0">Register</button>
            </div>
            <!-- /.col -->
        </div>
    </div>
</template>
<script>
import { required, email, url } from "vuelidate/lib/validators";
import CompanyLogo from '@/components/core/CompanyLogo'

export default {
    components: {
      CompanyLogo,
    },
    data() {
        return {
            errors: [],
            next_statge: false,
            terms: false,
            type_of_membership: [
                "Advertiser",
                "Publisher",
                "Agency",
                "Publisher Network",
            ],
            interested_in: [
                "Video",
                "Display(Banner ads)",
                "Rich-Media",
                "Mobile App Ads",
                "Programmatic",
            ],
            user: {
                first_name: null,
                last_name: null,
                username: null,
                email: null,
                password: null,
                password_confirm: null,
                phone_number: null,

                company_name: "",
                company_url: "",
                country: "",
                type_of_membership: [],
                intersted_in: [],
                websites: []
            },
            dark: localStorage.getItem("dark-mode")?"":"dark",
        }
    }, 
    methods: {
        emitLoginTruthyValue(){
            this.$emit("LoginTruthyValue", true);
        },
        signup: function() {
            this.errors = []
            var websites_list = []

            this.fieldCheck()

            // split the ads if are bulk
            var lines = this.user.websites
            if(typeof this.user.websites.split == 'function') {
                lines = this.user.websites.split("\n")
            }
            lines.forEach(function(website) {
                // remove any empty values
                if(website !== "") {
                    websites_list.push(website)
                }
            })
            this.user.websites = websites_list

            if(this.errors.length > 0) {
                return
            }

            this.$store.dispatch('SIGNUP', this.user).then(result => {
                this.$router.push("/thankyou")
            }).catch(err => {
                if('response' in err && 'data' in err.response) {
                    for (var value in err.response.data) {
                        if ( err.response.data.hasOwnProperty(value) ) {
                            var field = value.charAt(0).toUpperCase() + value.slice(1).replace('_', ' ')
                            this.$notify({
                                group: 'notifications',
                                type: 'error',
                                title: field + ' ' + err.response.data[value][0]
                            })
                            this.errors.push(field + ' ' + err.response.data[value][0])
                        }
                    }
                } else {
                    this.$notify({
                        group: 'notifications',
                        type: 'error',
                        title: 'Unknown error please try again.'
                    })
                }
            })
        },
        fieldCheck() {
            if(this.user.password !== this.user.password_confirm) {
                this.$notify({
                    group: 'notifications',
                    type: 'error',
                    title: 'Passwords not match.'
                })
                this.errors.push("password")
            }

            if(!this.user.username) {
                this.$notify({
                    group: 'notifications',
                    type: 'error',
                    title: 'Company Name is required.'
                })
                this.errors.push("username")
            }

            if(!this.user.first_name) {
                this.$notify({
                    group: 'notifications',
                    type: 'error',
                    title: 'First name is required.'
                })
                this.errors.push("first_name")
            }

            if(!this.user.last_name) {
                this.$notify({
                    group: 'notifications',
                    type: 'error',
                    title: 'Last name is required.'
                })
                this.errors.push("last_name")
            }

            if(!this.user.email) {
                this.$notify({
                    group: 'notifications',
                    type: 'error',
                    title: 'Email is required.'
                })
                this.errors.push("email")
            }

            if(!this.user.phone_number) {
                this.$notify({
                    group: 'notifications',
                    type: 'error',
                    title: 'Phone number is required.'
                })
                this.errors.push("phone_number")
            }

            if(!this.user.company_name) {
                this.$notify({
                    group: 'notifications',
                    type: 'error',
                    title: 'Company Name is required.'
                })
                this.errors.push("company_name")
            }

            if(!this.user.company_url) {
                this.$notify({
                    group: 'notifications',
                    type: 'error',
                    title: 'Company url is required.'
                })
                this.errors.push("company_name")
            }

            if(!this.user.country) {
                this.$notify({
                    group: 'notifications',
                    type: 'error',
                    title: 'Country is required.'
                })
                this.errors.push("country")
            }

            if(!this.user.type_of_membership) {
                this.$notify({
                    group: 'notifications',
                    type: 'error',
                    title: 'Type of membership is required.'
                })
                this.errors.push("type_of_membership")
            }

            if(!this.user.interested_in) {
                this.$notify({
                    group: 'notifications',
                    type: 'error',
                    title: 'interested in is required.'
                })
                this.errors.push("interested_in")
            }

            if(!this.user.websites) {
                this.$notify({
                    group: 'notifications',
                    type: 'error',
                    title: 'Website(s) is required.'
                })
                this.errors.push("Website")
            }

            if(this.terms == false) {
                this.$notify({
                    group: 'notifications',
                    type: 'error',
                    title: 'Please make sure to check the Terms of Service.'
                })
                this.errors.push("terms")
            }
                
        },
        websites_list() {

        }
    },
    computed: {
        isNotAuthenticated: function() {
            if(!this.$store.getters.isAuthenticated) {
                return true
            }
            this.$router.push('/')
        },
    }
}
</script>
<style scoped>
</style>