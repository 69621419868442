<template>
    <div class=" min-h-screen flex flex-col items-center py-10" v-if="isNotAuthenticated">
        <div class="-intro-x pt-5">
            <company-logo position="sidebar" />
        </div>
        <div class="box p-6 mt-12 w-11/12 sm:w-3/4 xl:w-1/3" >
            <img src="/assets/img/check.png" style="height: 100px; margin:auto;" />
            <h1 class="intro-x font-bold text-center" style="font-size: 48px; color: #494949;">Thanks for registering!</h1>
            <p class="intro-x font-bold text-center" style="color: #666666; font-family: Montserrat; font-size: 18px;">Please check your email. an email will be sent shortly with verification link.</p>
            <br />
            <p class="text-center">
            <router-link :to="{name: 'login'}" class="button button--md w-32 text-gray-700 border border-gray-300 dark:text-gray-200 dark:border-white" style="margin:auto;">Return to login</router-link>
            </p>
        </div>
    </div>
</template>
<script>
export default {
    computed: {
        isNotAuthenticated: function() {
            if(!this.$store.getters.isAuthenticated) {
                return true
            }
            this.$router.push('/')
        },
    }
}
</script>
<style>

</style>