<template>
<div class="col-span-12 mt-8">
    <div class="intro-y box">
        <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200">
          <h2 class="font-medium text-base mr-auto">Websites List</h2>

          <div class="intro-x relative">
            <div class="search hidden sm:block">
                <input type="search" name="table_search" class="search__input input placeholder-theme-13" placeholder="Search ..." v-model="search_text">
                <i data-feather="search" class="search__icon"></i>
              </div>
          </div>
        </div>
        <!-- /.card-header -->
        <div style="padding: 10px;">
              <ul class="nav nav-pills flex-column">
                <li class="items-center" v-if="isLoading">
                  <point-spinner />
                </li>
                <li class="intro-x" v-if="websites.length == 0 && !isLoading">
                  <a href="#" class="WL-bg-dark flex items-center p-3 cursor-pointer transition duration-300 ease-in-out bg-white hover:bg-gray-200 rounded-md">
                    No Available websites
                  </a>
                </li>
                <li class="intro-x" v-for="website in websites" :key="website.id">
                  <a :href="'tags/' + website.id" class="WL-bg-dark flex items-center p-3 cursor-pointer transition duration-300 ease-in-out bg-white hover:bg-gray-200 rounded-md" style="cursor: pointer;" @click.prevent="selectedWebsite(website)">
                    <div class="pos__ticket__item-name truncate mr-1">{{ website.name }}</div>
                    <div class="ml-auto">
                      <span class="py-1 px-2 rounded-full text-xs text-white cursor-pointer font-medium" :class="{
                          'bg-theme-9': website.status == 'active',
                          'bg-theme-4': website.status == 'pending',
                          'bg-theme-6': website.status == 'suspended'}"> {{ website.status | capitalize }} 
                      </span>
                    </div>
                  </a>
                </li>
              </ul>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        websites: {
          required: true,
          type: Array
        },
        isLoading: {
          required: true,
          type: Boolean
        }
    },
    data() {
        return {
          search_text: ""
        }
    },
    methods: {
        selectedWebsite(website) {
          this.$emit("selected", website)
        },
        loadSearchResult() {
          this.$emit("search", this.search_text)
        }
    },
    watch: {
      search_text: function() {
        this.loadSearchResult()
      }
    }
}
</script>

<style scoped>

</style>