const state = {
    whitelist: JSON.parse(localStorage.getItem('whitelist')) || {},
    response: false,
    main_label: [
        'https://services.vidintop.com',
        'https://publisher.vidintop.com'
    ],
    current_label: window.location.origin
}

const getters = {
    whitelist(state) {
        return state.whitelist
    },
    response(state) {
        return state.response
    },
    isMainLabel(state) {
        return state.main_label.includes(state.current_label)
    }
}

const mutations = {
    whitelist(state, data) {
        localStorage.setItem('whitelist', JSON.stringify(data) )
        state.whitelist = JSON.parse(localStorage.getItem('whitelist'));
    },
    response(state, data) {
        state.response = data
    }
}

const actions = {
}

export default {
  state,
  getters,
  mutations,
  actions
}
