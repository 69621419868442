<template>
<div>
  <!-- Main Sidebar Container -->
  <!-- BEGIN: Side Menu -->
  <nav class="side-nav px-4">
      <a href="" class="intro-x flex items-center pl-5 pt-4">
          <CompanyLogo position="sidebar" />
      </a>
      <div class="side-nav__devider my-6"></div>
      <ul>
          <li>
            <router-link :to="{ name: 'dashboard'}" exact-active-class="side-menu--active" class="side-menu">
              <div class="side-menu__icon"> <i data-feather="home"></i> </div>
              <div class="side-menu__title"> Dashboard </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'tags'}" active-class="side-menu--active" class="side-menu">
              <div class="side-menu__icon"> <i data-feather="tag"></i> </div>
              <div class="side-menu__title"> Tags </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ads'}" active-class="side-menu--active" class="side-menu">
              <div class="side-menu__icon"> <i data-feather="file-text"></i> </div>
              <div class="side-menu__title"> Ads.txt </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'reports'}" active-class="side-menu--active" class="side-menu">
              <div class="side-menu__icon"> <i data-feather="pie-chart"></i> </div>
              <div class="side-menu__title"> Reports </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'request_new_format'}" active-class="side-menu--active" class="side-menu">
              <div class="side-menu__icon"> <i data-feather="plus-square"></i> </div>
              <div class="side-menu__title"> Request New Format </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'report_ad'}" active-class="side-menu--active" class="side-menu">
              <div class="side-menu__icon"> <i data-feather="message-circle"></i> </div>
              <div class="side-menu__title"> Report Ad </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'wallets'}" active-class="side-menu--active" class="side-menu">
              <div class="side-menu__icon"> <i data-feather="shield"></i> </div>
              <div class="side-menu__title"> Wallet </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'profile'}" active-class="side-menu--active" class="side-menu">
              <div class="side-menu__icon"> <i data-feather="user"></i> </div>
              <div class="side-menu__title"> Profile </div>
            </router-link>
          </li>
          <template v-if="$store.getters.isMainLabel">
            <li class="side-nav__devider my-6"></li>
            <li>
              <a href="http://www.vidintop.com/outstream/" target="_blank" active-class="side-menu--active" class="side-menu">
                <div class="side-menu__icon"> <i data-feather="video"></i> </div>
                <div class="side-menu__title"> Discover OutStream </div>
              </a>
            </li>
            <li>
              <a href="mailto:support@vidintop.com" target="_blank" active-class="side-menu--active" class="side-menu">
                <div class="side-menu__icon"> <i data-feather="help-circle"></i> </div>
                <div class="side-menu__title"> Support </div>
              </a>
            </li>
          </template>
      </ul>

  </nav>
  <!-- END: Side Menu -->
</div>
</template>
<script>
import CompanyLogo from '@/components/core/CompanyLogo'

export default {
  name: 'Sidebar',
  cache: false,
  components: {
    CompanyLogo
  },
  props: {
    company_logo: {
      type: String,
    },
    user: {
      type: Object,
    },
  },
  created() {

  },
  methods: {
    logout() {
      if (event) event.preventDefault()
      this.$store.dispatch('AUTH_LOGOUT').then(() => {
        this.$router.push('/login')
      })
    }
  }, 
  computed: {
    userFullName() {
      return this.user.first_name + " " + this.user.last_name
    },
    verifiedUser() {
      if(this.user.is_verified) {
        return 'Verified'
      } else {
        return 'Not Verified'
      }
    },
  }
}
</script>

<style scoped>

</style>