import Vue from 'vue'
import Router from 'vue-router'
// accounts pages
import Login from '@/components/account/Login.vue'
import Signup from '@/components/account/Signup.vue'
import ForgotPassword from '@/components/account/ForgotPassword'
import ResetPassword from '@/components/account/ResetPassword'
import VerifyAccount from '@/components/account/VerifyAccount.vue'

// main pages
import Home from '@/views/Home.vue'
import Dashboard from '@/views/Dashboard'
import Ads from '@/views/Ads.vue'
import Analytics from '@/views/Analytics.vue'
import Tags from '@/views/Tags.vue'

import Wallets from '@/views/Wallets.vue'
import Wallet from '@/components/wallets/Wallet.vue'

import Profile from '@/views/Profile.vue'
import AccountProfile from '@/components/account/profile/profile.vue'
import AccountBankAccount from '@/components/account/profile/bank.vue'
import AccountLogin from '@/components/account/profile/login.vue'

//
import RequestNewFormat from '@/views/RequestNewFormat.vue'
import ReportAd from '@/views/ReportAd.vue'

// Notifications view
import Notifications from "@/components/notifications/Notifications.vue"
import Messages from "@/components/notifications/Messages.vue"

// errors
import Error404 from '@/views/Errors/404.vue'
import ErrorNotVerified from '@/views/Errors/Verify.vue'
import ErrorDenied from '@/views/Errors/Denied.vue'
import ErrorSuspended from '@/views/Errors/Suspended.vue'

// messages
import RegistrationThankYou from '@/views/Messages/RegistrationThankYou.vue'

import store from '@/store/store'

Vue.use(Router)

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') throw err
  });
}


const logoutUser = (to, from, next) => {
  store.Auth.dispatch('AUTH_LOGOUT')
  next({name: 'login'})
}

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
  {
    path: '/login',
    name: 'login',
    component: Login,
    props: route => ({ login: true }),
    meta: { 
      requiresAuth: false
    }
  },
  {
    path: '/signup',
    name: 'signup',
    component: Login,
    props: route => ({ signup: true }),
    meta: { 
      requiresAuth: false
    }
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: Login,
    props: route => ({ forgot: true }),
    meta: { 
      requiresAuth: false
    }
  },
  {
    path: '/reset/:key',
    name: 'reset',
    component: ResetPassword,
    meta: { 
      requiresAuth: false
    }
  },
  {
    path: '/verify/:key',
    name: 'verify',
    component: VerifyAccount,
    meta: { 
      requiresAuth: false
    }
  },
  {
    path: '/thankyou',
    name: 'thankyou',
    component: RegistrationThankYou,
    meta: { 
      requiresAuth: false
    }
  },
  {
    // the main user dashboard
    path: '/',
    component: Home,
    meta: { 
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'dashboard',
        component: Dashboard,
      },
      {
        path: 'tags',
        name: 'tags',
        component: Tags,
      },    
      {
        path: 'ads',
        name: 'ads',
        component: Ads,
      },
      {
        path: 'reports',
        name: 'reports',
        component: Analytics,
      },
      {
        path: 'request_new_format',
        name: 'request_new_format',
        component: RequestNewFormat,
      },
      {
        path: 'report_ad',
        name: 'report_ad',
        component: ReportAd,
      },
      {
        path: 'wallets',
        name: 'wallets',
        component: Wallets,
      },
      {
        path: 'wallets/:id?',
        name: 'wallet',
        component: Wallet,
      },
      {
        path: 'profile',
        name: 'profile',
        component: Profile,
        children: [  
          {
            path: '',
            name: 'profile',
            component: AccountProfile
          },
          {
            path: 'info',
            name: 'profile-info',
            component: AccountProfile
          },
          {
            path: 'bank',
            name: 'bank',
            component: AccountBankAccount
          },
          {
            path: 'credentials',
            name: 'credentials',
            component: AccountLogin
          },
        ]
      },
      {
        path: '/notifications',
        name: 'notifications',
        component: Notifications,
      },
      {
        path: '/notifications/:id?',
        name: 'notification',
        component: Notifications
      },
      {
        path: '/messages',
        name: 'messages',
        component: Messages,
        children:[
          {
            path: ':id',
            name: 'message',
            component: Messages
          },
        ]
      },
      {
        path: 'logout',
        name: 'logout',
        component: ErrorDenied,
        beforeEnter: logoutUser,
      },
      {
        path: 'denied',
        name: 'denied',
        component: ErrorDenied,
        meta: { 
          requiresAuth: true
        }
      },
      {
        path: 'suspended',
        name: 'suspended',
        component: ErrorSuspended,
        meta: { 
          requiresAuth: true
        }
      },
      {
        path: 'not_verified',
        name: 'not_verified',
        component: ErrorNotVerified,
        meta: { 
          requiresAuth: true
        }
      },
      {
        // Error message for unavailable pages
        path: '*',
        name: 'Not Found',
        component: Error404
      },
    ]
  },
]})
