<template>
    <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12 xxl:col-span-12 grid grid-cols-12 gap-6">
            <Revenue :revenue="revenue_status" :isLoading="isLoading" />
            <Impressions :impressions="impressions_status" :isLoading="isLoading" />
            <Graph :yesterday_overall="yesterday_overall_status" :yesterday_overall_loading="isLoading" />
            <Websites :websites="websites_status" :isLoading="isLoading" />
            <AdFormat />
        </div>
    </div>    
</template>

<script>

import { RepositoryFactory } from '@/services/repositories/PublisherAPI/RepositoryFactory'
const WebsitesRepo = RepositoryFactory.get('websites')
const WebsiteStatisticsRepo = RepositoryFactory.get('websites_statistics')

import Graph from '@/components/dashboard/graph/Graph'
import Revenue from '@/components/dashboard/Revenue'
import Impressions from '@/components/dashboard/Impressions'
import Websites from '@/components/dashboard/Websites'
import AdFormat from '@/components/dashboard/Placement_Groups'

export default {
    name: 'Dashboard',
    components: {
        Graph,
        Revenue,
        Impressions,
        Websites,
        AdFormat,
    },
    data() {
        return {
            revenue_status: [],
            impressions_status: [],
            yesterday_overall_status: [],
            websites_status: [],
            adformat_status: [],
            isLoading: true
        }
    },
    created() {
        this.loadWebsiteStatisticsStatus()
    },
    mounted() {
        this.$feather.replace()
    },
    methods: {
        loadWebsiteStatisticsStatus: function() {
            this.isLoading = true
            WebsiteStatisticsRepo.status().then(result => {
                this.revenue_status = result.data.revenue
                this.impressions_status = result.data.impressions
                this.yesterday_overall_status = result.data.yesterday_overall
                this.websites_status = result.data.websites

                this.isLoading = false
            }).catch(err => {
                this.isLoading = false
            })
        },
    },
    computed: {
    },
    watch: {
    },
}
</script>
<style>

</style>