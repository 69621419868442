<template>
<!-- BEGIN: Mobile Menu -->
<div class="mobile-menu md:hidden">
    <div class="mobile-menu-bar mx-4 mt-6 ">
        <div  class="flex mr-auto inline-block ">
          <CompanyLogo position="sidebar" class="w-40 object-contain" />
        </div>
        <a href="javascript:;" class="mobile-menu-toggler"> <i data-feather="bar-chart-2" class="w-8 h-8 text-white transform -rotate-90"></i> </a>
    </div>
    <ul class="border-t py-5 px-5 hidden">
          <li>
            <router-link :to="{ name: 'dashboard'}"  class="menu menu--active mobile-menu-toggler">
              <div class="menu__icon"> <i data-feather="home"></i> </div>
              <div class="menu__title"> Dashboard </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'tags'}"  class="menu mobile-menu-toggler">
              <div class="menu__icon"> <i data-feather="tag"></i> </div>
              <div class="menu__title"> Tags </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ads'}"  class="menu mobile-menu-toggler">
              <div class="menu__icon"> <i data-feather="file-text"></i> </div>
              <div class="menu__title"> Ads.txt </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'reports'}"  class="menu mobile-menu-toggler">
              <div class="menu__icon"> <i data-feather="pie-chart"></i> </div>
              <div class="menu__title"> Reports </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'request_new_format'}"  class="menu mobile-menu-toggler">
              <div class="menu__icon"> <i data-feather="plus-square"></i> </div>
              <div class="menu__title"> Request New Format </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'report_ad'}"  class="menu mobile-menu-toggler">
              <div class="menu__icon"> <i data-feather="message-circle"></i> </div>
              <div class="menu__title"> Report Ad </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'wallets'}"  class="menu mobile-menu-toggler">
              <div class="menu__icon"> <i data-feather="shield"></i> </div>
              <div class="menu__title"> Wallet </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'profile'}"  class="menu mobile-menu-toggler">
              <div class="menu__icon"> <i data-feather="user"></i> </div>
              <div class="menu__title"> Profile </div>
            </router-link>
          </li>
          <template v-if="$store.getters.isMainLabel">
          <hr class="m-3">
          <li>
            <a href="http://www.vidintop.com/outstream/" target="_blank"  class="menu mobile-menu-toggler">
              <div class="menu__icon"> <i data-feather="video"></i> </div>
              <div class="menu__title"> Discover OutStream </div>
            </a>
          </li>
          <li>
            <a href="mailto:support@vidintop.com" target="_blank" class="menu mobile-menu-toggler">
              <div class="menu__icon"> <i data-feather="help-circle"></i> </div>
              <div class="menu__title"> Support </div>
            </a>
          </li>
          </template>
    </ul>
</div>
  <!-- END: Mobile Menu -->
</template>
<script>
import CompanyLogo from '@/components/core/CompanyLogo'

export default {
  name: 'Sidebar',
  cache: false,
  components: {
    CompanyLogo
  },
  props: {
    company_logo: {
      type: String,
    },
    user: {
      type: Object,
    },
  },
  mounted() {
    "use strict"; // Mobile Menu

    $('.mobile-menu-toggler').on('click', function () {
      if ($('.mobile-menu').find('ul').first().is(':visible')) {
        $('.mobile-menu').find('ul').first().slideUp();
      } else {
        $('.mobile-menu').find('ul').first().slideDown();
      }
    });
    
  },
  methods: {
    logout() {
      if (event) event.preventDefault()
      this.$store.dispatch('AUTH_LOGOUT').then(() => {
        this.$router.push('/login')
      })
    }
  }, 
  computed: {
    userFullName() {
      return this.user.first_name + " " + this.user.last_name
    },
    verifiedUser() {
      if(this.user.is_verified) {
        return 'Verified'
      } else {
        return 'Not Verified'
      }
    },
  }
}
</script>

<style scoped>

</style>