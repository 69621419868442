<template>
    <div class="login-logo" v-animate-css="'fadeInUp'">
        <a href=""> 
            <img :src="logo" alt="Logo" class=""
            style="opacity: .9; width: 300px;">
            <span class="brand-text font-weight-light"></span>
        </a>
    </div>
</template>
<script>
export default {
   props: {
        position: {
            type: String,
            required: false,
        }
    },
    data() {
        return {
            dark: localStorage.getItem("dark-mode")? true : false,
        }
    },
    computed: {
        logo: function() {
            if(!this.dark && this.position == 'sidebar') {
                if(this.$store.getters.whitelist.logo_dark == '') {
                    return this.$store.getters.whitelist.logo
                }
                return this.$store.getters.whitelist.logo_dark
            }

            if(this.dark && this.$store.getters.whitelist.logo_dark != null && this.$store.getters.whitelist.logo_dark != '') {
                return this.$store.getters.whitelist.logo_dark
            }
            return this.$store.getters.whitelist.logo
        }
    }
 
}
</script>
<style scoped>

</style>